pos.store.factory("checkoutService", function($rootScope, $localStorage, $http, $cookies, $timeout, session, globalData, globalFunctions){

	var additionalOrderData = function(inventoryPoolID,orderNotes,orderType,requestedShipDay,requestedShipMonth,requestedShipYear,salesAssociateID,salesAttributeID,sourceCode,isNonTaxable,outsideCheckout,callback){
		globalFunctions.v65http("/store/index.cfm?method=checkout.additionalOrderData",{inventoryPoolID:inventoryPoolID,orderNotes:orderNotes,orderType:orderType,requestedShipDay:requestedShipDay,requestedShipMonth:requestedShipMonth,requestedShipYear:requestedShipYear,salesAssociateID:salesAssociateID,salesAttributeID:salesAttributeID,sourceCode:sourceCode,isNonTaxable:isNonTaxable,outsideCheckout:outsideCheckout, orderID:globalFunctions.retrieveOrderIDFromSession(),posProfile:session.posProfile},function(response){
			_updateSession(response);
			if(globalData.posInfo.simpleWorkflow){
				response.nextModal = _nextModal("simpleSuccess");
			} else {
				response.nextModal = _nextModal("success");
			}
			(callback)(response);
		}, null, null, 0);
	};

	var alternatePaymentMethod = function(amountTendered, paymentType, callback){
		globalFunctions.v65http("/store/index.cfm?method=checkout.alternatePaymentType", {amountTendered:amountTendered,paymentType:paymentType, posProfile:session.posProfile,orderID:globalFunctions.retrieveOrderIDFromSession()}, function(response){
			_updateSession(response);
			response.nextModal = _nextModal("process");
			if(callback) (callback)(response);
		}, null, null, 0);
	};

	var cameraCaptureCard = function(cardNumber, cardExpiryMo, cardExpiryYr, cvv2, callback){
		var amountTendered = globalFunctions.isRefund() ? session.refund.refundHeader.amountDueCents : session.order.orderHeader.amountDueCents
		globalFunctions.v65http("/store/index.cfm?method=checkout.cameraCaptureCard",{
			amountTendered:(amountTendered / 100).toFixed(2),
			cardNumber:cardNumber,
			cardExpiryMo:cardExpiryMo,
			cardExpiryYr:cardExpiryYr,
			cvv2:cvv2,
			posProfile:session.posProfile,
			productTransactionId: session.posProfile.zeamsterProductTransactionID,
			orderID:globalFunctions.retrieveOrderIDFromSession()
		},function(response){
			_updateSession(response);
			_setPaymentType("CreditCard");
			response.nextModal = _nextModal("tip");
			if(response.hasAdditionalOrderData){
				angular.copy(response.additionalOrderData, globalData.additionalOrderData);
			}
			(callback)(response);
		}, null, null, 0);
	}

	var captureSignature = function(orderTenderID, signature, orderID, callback){
		signature = signature.replace("data:image/png;base64,", "");
		globalFunctions.v65http("/store/index.cfm?method=checkout.captureSignature",{orderTenderID:orderTenderID,signature:signature,orderID:orderID,posProfile:JSON.stringify(session.posProfile)}, function (response) {
			response.nextModal = _nextModal("process");
			(callback)(response);
		},"POST", null, 0);
	}

	var capturePayment = function(productTransactionID, callback){
		globalFunctions.v65http("/store/index.cfm?method=checkout.capturePayment", {
			productTransactionID: productTransactionID,
			posProfile:session.posProfile,
			orderID:globalFunctions.retrieveOrderIDFromSession()
		}, function(response){
			if(callback) (callback)(response);
		}, null, null, 0);
	};
	
	var voidAndReAuthPayment = function(callback){
		globalFunctions.v65http("/store/index.cfm?method=checkout.voidAndReAuthPayment", {posProfile:session.posProfile,orderID:globalFunctions.retrieveOrderIDFromSession()}, function(response){
			if(callback) (callback)(response);
		}, null, null, 0);
	};
	
	var deleteCartObject = function(callback){
		globalFunctions.v65http("/store/index.cfm?method=checkout.deleteCartObject", {posProfile:session.posProfile,orderID:globalFunctions.retrieveOrderIDFromSession()}, function(response){
			if(callback) (callback)(response);
		}, null, null, 0);
	};

	var enterCardManually = function(
		amountTendered,
		cardNumber,
		cardExpiryMo,
		cardExpiryYr,
		cvv2,
		isSplitPayment,
		splitCount,
		productTransactionID,
		callback
	) {
		globalFunctions.v65http("/store/index.cfm?method=checkout.enterCardManually",{
			amountTendered:amountTendered,
			cardNumber:cardNumber,
			cardExpiryMo:cardExpiryMo,
			cardExpiryYr:cardExpiryYr,
			cvv2:cvv2,
			posProfile:session.posProfile,
			orderID:globalFunctions.retrieveOrderIDFromSession(),
			posDevice:session.posDeviceProfile,
			isSplitPayment: isSplitPayment,
			splitCount: splitCount,
			productTransactionID: productTransactionID
		},function(response){
			_updateSession(response);
			_setPaymentType("CreditCard");
			response.nextModal = _nextModal("tip");
			if(response.hasAdditionalOrderData){
				angular.copy(response.additionalOrderData, globalData.additionalOrderData);
			}
			(callback)(response);
		}, null, null, 0);
	}
	
	var getAdditionalEmailsForContact = function(customerID, callback){
		globalFunctions.v65http("/store/index.cfm?method=checkout.getAdditionalEmailsForContact",{customerID:customerID},function(response){
			(callback)(response);
		}, null, 15000, 0);
	};

	var emailAddress = function(email, firstName, lastName, zipCode, isSingleOptIn, includeSecondaryEmails, callback){
		globalFunctions.v65http("/store/index.cfm?method=checkout.emailAddress",{email:email,firstName:firstName,lastName:lastName,zipCode:zipCode,isSingleOptIn:isSingleOptIn,includeSecondaryEmails:includeSecondaryEmails,posProfile:session.posProfile,orderID:globalFunctions.retrieveOrderIDFromSession()}, function(response){
			_updateSession(response);
			if(globalData.posInfo.simpleWorkflow){
				response.nextModal = _nextModal("simpleSuccess");
			} else {
				response.nextModal = _nextModal("additionalOrderData");
			}
			(callback)(response);
		}, null, 15000);
	}

	var noReceipt = function(callback){
		globalFunctions.v65http("/store/index.cfm?method=checkout.noReceipt",{posProfile:session.posProfile,orderID:globalFunctions.retrieveOrderIDFromSession()}, function(response){
			_updateSession(response);
			response.nextModal = _nextModal("additionalOrderData");
			(callback)(response);
		}, null, 15000);
	}
	
	var emailReceiptForm = function(callback){
		globalFunctions.v65http("/store/index.cfm?method=checkout.emailReceiptForm",{posProfile:session.posProfile, orderID:globalFunctions.retrieveOrderIDFromSession()}, function(response){
			_updateSession(response);
			response.nextModal = _nextModal("emailReceipt");
			(callback)(response);
		}, null, 15000);
	}
	
	var cancelEmailReceipt = function(callback){
		globalFunctions.v65http("/store/index.cfm?method=checkout.cancelEmailReceipt",{posProfile:session.posProfile, orderID:globalFunctions.retrieveOrderIDFromSession()}, function(response){
			_updateSession(response);
			response.nextModal = _nextModal("simpleSuccess");
			(callback)(response);
		}, null, 15000);
	}

	var listAdditionalOrderData = function(callback){
		globalFunctions.v65http("/store/index.cfm?method=checkout.listAdditionalOrderData", {posProfile:session.posProfile,orderID:globalFunctions.retrieveOrderIDFromSession()} ,function(response){
			angular.copy(response.additionalOrderData, globalData.additionalOrderData);
			if(callback) (callback)(response);
		}, null, 15000);
	}

	var listPaymentTypes = function(callback){
		globalFunctions.v65http("/store/index.cfm?method=checkout.listPaymentTypes", {posProfile:session.posProfile,orderID:globalFunctions.retrieveOrderIDFromSession()} ,function(response){
			var paymentTypes = response.paymentTypes.reverse().filter(function(e){return e.paymentTypeMethod == "Custom"});
			angular.copy(paymentTypes, session.paymentTypes);
			if(callback) (callback)(response);
		}, null, 15000);
	}

	var removeOrderTender = function(orderTenderID,callback){
		globalFunctions.v65http("/store/index.cfm?method=checkout.removeOrderTender", {orderTenderID:orderTenderID,posProfile:session.posProfile,orderID:globalFunctions.retrieveOrderIDFromSession()}, function(response){
			_updateSession(response);
			if(callback) (callback)(response)
		}, null, null, 0);
	}

	var swipeCreditCard = function(amountTendered, cardSwipe, callback){
		globalFunctions.v65http("/store/index.cfm?method=checkout.swipeCreditCard",{
			amountTendered:amountTendered,
			cardSwipe:cardSwipe,
			posProfile:session.posProfile,
			productTransactionId: session.posProfile.zeamsterProductTransactionID,
			orderID:globalFunctions.retrieveOrderIDFromSession(),
			posDevice:session.posDeviceProfile
		},function(response){
			_updateSession(response);
			_setPaymentType("CreditCard");
			response.nextModal = _nextModal("tip");
			if(response.hasAdditionalOrderData){
				angular.copy(response.additionalOrderData, globalData.additionalOrderData);
			}
			(callback)(response);
		}, null, null, 0);
	}
	
	var swipeGiftCard = function(amountTendered, cardSwipe, callback){
		globalFunctions.v65http("/store/index.cfm?method=checkout.swipeGiftCard",{giftCardAmountTendered:amountTendered,cardSwipe:cardSwipe,posProfile:session.posProfile,orderID:globalFunctions.retrieveOrderIDFromSession()},function(response){
			_updateSession(response);
			response.nextModal = _nextModal("process");

			if(response.hasAdditionalOrderData){
				angular.copy(response.additionalOrderData, globalData.additionalOrderData);
			}
			_updateSession(response);
			(callback)(response);
		}, null, null, 0);
	}
	

	var payWithCash = function(amountTendered, cashTendered, callback){
		globalFunctions.v65http("/store/index.cfm?method=checkout.payWithCash",{amountTendered:amountTendered,cashTendered:cashTendered,posProfile:session.posProfile,orderID:globalFunctions.retrieveOrderIDFromSession()},function(response){
			_updateSession(response);
			response.nextModal = _nextModal("process");
			if(response.hasAdditionalOrderData){
				angular.copy(response.additionalOrderData, globalData.additionalOrderData);
			}
			(callback)(response);
		}, null, null, 0);
	}

	var payWithLoyaltyPoints = function(amountTendered, loyaltyPointsTendered, callback){
		globalFunctions.v65http("/store/index.cfm?method=checkout.payWithLoyaltyPoints",{amountTendered:amountTendered,loyaltyPointsTendered:loyaltyPointsTendered,posProfile:session.posProfile,orderID:globalFunctions.retrieveOrderIDFromSession()},function(response){
			_updateSession(response);
			response.nextModal = _nextModal("process");
			if(response.hasAdditionalOrderData){
				angular.copy(response.additionalOrderData, globalData.additionalOrderData);
			}
			(callback)(response);
		}, null, null, 0);
	}

	var payWithTerminal = function(amountTendered,tip,authCode,transactionID,reasonCode,cardNumber,paymentTerminal,cardType,nameOnCard,callback){
		globalFunctions.v65http("/store/index.cfm?method=checkout.payWithTerminal",{amountTendered:amountTendered,tip:tip,authCode:authCode,transactionID:transactionID,reasonCode:reasonCode,cardNumber:cardNumber,paymentTerminalNickname:paymentTerminal.nickName,paymentTerminalKey:paymentTerminal.paymentTerminalKey,paymentTerminalPassword:paymentTerminal.paymentTerminalPassword,paymentTerminalID:paymentTerminal.paymentTerminalID,posProfile:session.posProfile,orderID:globalFunctions.retrieveOrderIDFromSession(),cardType:cardType,nameOnCard:nameOnCard}, function(response){
			_updateSession(response);
			response.nextModal = _nextModal("signature");
			if(callback) (callback)(response);
		}, null, null, 0);
	};

	var payWithEMVTerminal = function(
		amount,
		terminalID,
		locationID,
		productTransactionID,
		orderID,
		terminalNickname,
		transactionType,
		orderNumber,
		previousOrderNumber,
		orderType,
		isSplitPayment,
		splitCount,
		isRetry,
		retryAttempts,
		failedTender,
		callback
	) {
		var timestamp = Date.now();
		/**
		 * Javascript it will wait for the response 303000 miliseconds 5.05 mins
		 * The Coldfusion timeout is 302000 miliseconds 5.03 mins
		 * HTTP Request for the API in Coldfusion (routerTransaction endpoint) it will be open awaiting for Response for 300000 miliseconds 5 mins as Zeamster/Fortis documentation requires
		 * After exceeding the time limit it will throw an exception and it will be captured and return a simple message error
		 */
		var requestTimeout = 303000;
		var data = {
			amount: amount,
			terminalID: terminalID,
			locationID: locationID,
			productTransactionID: productTransactionID,
			terminalNickname: terminalNickname,
			transactionType: transactionType,
			orderID: orderID,
			orderNumber: orderNumber,
			previousOrderNumber: previousOrderNumber,
			orderType: orderType,
			ts:timestamp,
			isSplitPayment: isSplitPayment,
			splitCount: splitCount,
			posDevice:session.posDeviceProfile,
			isRetry: isRetry,
			retryAttempts: retryAttempts,
			failedTender: failedTender
		};

		globalFunctions.v65http("/store/index.cfm?method=checkout.payWithEMVTerminal", data, function(response){
			_updateSession(response);
			// Only move on if there was no error (successful payment)
			if(response.payWithEMVTerminal.alertLevel.length === 0){
				response.nextModal = _nextModal("process");
			}
			if(callback) (callback)(response);
		}, null, requestTimeout, 0, "retry", function(response) {
			console.log(response);
			globalFunctions.alertMessage("modal", "warning", "Sorry the payment gateway is a little slow today, please retry.");
		});
	};

	var payWithFullsteamEMV = function(
		amount,
		terminalID,
		orderID,
		terminalNickname,
		transactionType,
		orderNumber,
		previousOrderNumber,
		orderType,
		isSplitPayment,
		splitCount,
		isRetry,
		retryAttempts,
		failedTender,
		callback
	) {

		var timestamp = Date.now();
		/**
		 * Javascript it will wait for the response 303000 miliseconds 5.05 mins
		 * The Coldfusion timeout is 302000 miliseconds 5.03 mins
		 * HTTP Request for the API in Coldfusion (routerTransaction endpoint) it will be open awaiting for Response for 300000 miliseconds 5 mins as Fullsteam requires
		 * After exceeding the time limit it will throw an exception and it will be captured and return a simple message error
		 */
		var requestTimeout = 303000;
		var data = {
			amount: amount,
			terminalID: terminalID,
			terminalNickname: terminalNickname,
			transactionType: transactionType,
			orderID: orderID,
			orderNumber: orderNumber,
			previousOrderNumber: previousOrderNumber,
			orderType: orderType,
			ts:timestamp,
			isSplitPayment: isSplitPayment,
			splitCount: splitCount,
			posDevice:session.posDeviceProfile,
			isRetry: isRetry,
			retryAttempts: retryAttempts,
			failedTender: failedTender,
			posProfile: JSON.stringify(session.posProfile)
		};
		globalFunctions.v65http("/store/index.cfm?method=checkout.payWithFullsteamEMV", data, function(response){
			_updateSession(response);

			// Only move on if there was no error (successful payment)
			if(response.payWithFullsteamEMV.alertLevel.length === 0){
				response.nextModal = _nextModal("process");
			}

			if(callback) (callback)(response);

		}, null, requestTimeout, 0, "retry", function(response) {
			console.log(response);
			globalFunctions.alertMessage("modal", "warning", "Issue connecting to EMV terminal, please ensure terminal is powered on and connected to the network and then retry, or close and change devices.");
		});
	};


	var payWithTerminalStart = function(callback){
		response = {}
		_setPaymentType("Terminal");
		$timeout(function(){
			response.nextModal = _nextModal("tip");
			if(callback) (callback)(response);
		},300)
	};

	var payWithEMVTerminalStart = function(callback){
		response = {}
		_setPaymentType("Terminal");
		$timeout(function(){
			response.nextModal = _nextModal("terminal");
			if(callback) (callback)(response);
		},300)
	};

	var payWithFullsteamEMVStart = function(callback){
		response = {}
		_setPaymentType("Terminal");
		$timeout(function(){
			response.nextModal = _nextModal("fullsteamEMV");
			if(callback) (callback)(response);
		},300)
	};
	
	
	var payWithVivoTerminalStart = function(callback){
		response = {}
		_setPaymentType("Terminal");
		$timeout(function(){
			response.nextModal = _nextModal("terminal-ble");
			if(callback) (callback)(response);
		},300)
	};

	var payWithVivoTerminalBeginChipEmulate = function(data, callback, cancel) {
		globalFunctions.v65http(
			"/store/index.cfm?method=checkout.payWithVivoTerminalBeginChipEmulate",
			Object.fromEntries(new URLSearchParams(data)),
			function(response){ console.log(response); if(callback) (callback)(response);},
			null,
			cancel,
			0
		);
	}

	var payWithVivoTerminalInit = function (
		amount,
		tip,
		terminalID,
		locationID,
		productTransactionID,
		terminalNickname,
		orderID,
		orderNumber,
		orderType,
		previousOrderNumber,
		callback
	) {
		console.log('payWithVivoTerminalInit',data);

		var data = {
			amount: amount,
			tip: tip,
			terminalID: terminalID,
			locationID: locationID,
			productTransactionID: productTransactionID,
			terminalNickname: terminalNickname,
			orderID: orderID,
			orderNumber: orderNumber,
			posDevice: session.posDeviceProfile,
			orderType: orderType,
			previousOrderNumber: previousOrderNumber
		}
		globalFunctions.v65http(
			"/store/index.cfm?method=checkout.payWithVivoTerminalInit",
			data,
			function(response){
			_updateSession(response);
			if(callback) (callback)(response);
		}, null, null, 0);
	}

	var payWithVivoTerminalComplete = function (
		transactionData,
		amount,
		tip,
		orderTenderID,
		callback
	) {
		var data = {
			amount: amount,
			tip: tip,
			transaction: transactionData,
			orderID: globalFunctions.retrieveOrderIDFromSession(),
			orderTenderID: orderTenderID,
			posDevice: session.posDeviceProfile
		}
		globalFunctions.v65http(
			"/store/index.cfm?method=checkout.payWithVivoTerminalComplete",
			data,
			function(response){
				_updateSession(response);
				// Only move on if there was no error (successful payment)
				if(response.payWithVivoTerminalComplete.alertLevel.length === 0){
					response.nextModal = _nextModal("process");
				}
				if(callback) (callback)(response);
			}, null, null, 0);
	};

	var payWithVivoTerminal = function(data, callback) {
		globalFunctions.v65http("/store/index.cfm?method=checkout.payWithEMVTerminal",{
			successful:data.successful,
			amountTendered:data.transaction_amount,
			tip:data.tip_amount,
			authCode:data.auth_code,
			transactionID:data.id,
			reasonCode:data.reason_code_id,
			cardNumber:data.first_six+'00000000'+data.last_four,
			terminalNickname:"ID Tech: " + data.terminal_serial_number,
			posProfile:session.posProfile,
			orderID:globalFunctions.retrieveOrderIDFromSession(),
			nameOnCard:'',
			resultBlob:data
		}, function(response){
			console.log(response);
			_updateSession(response);
			// Only move on if there was no error (successful payment)
			if(response.payWithEMVTerminal.alertLevel.length === 0){
				response.nextModal = _nextModal("process");
			}
			if(callback) (callback)(response);
		}, null, null, 0);
	};

	var payWithVivoTerminalAlreadyProcessed = function(data, callback) {
		globalFunctions.v65http("/store/index.cfm?method=checkout.payWithVivoTerminalAlreadyProcessed",{
			orderTenderID: data.orderTenderID,
			orderID: data.orderID,
		}, function(response){
			console.log(response);
			_updateSession(response);
			// Only move on if there was no error (successful payment)
			if(response.payWithVivoTerminalAlreadyProcessed.alertLevel.length === 0){
				response.nextModal = _nextModal("process");
			}
			if(callback) (callback)(response);
		}, null, null, 0);
	};

	var payWithVivoTerminalCloseTender = function(data, callback) {
		globalFunctions.v65http("/store/index.cfm?method=checkout.payWithVivoTerminalCloseTender",{
			orderTenderID: data.orderTenderID,
			orderID: data.orderID,
		}, function(response){
			console.log(response);
			_updateSession(response);
			// Only move on if there was no error (successful payment)
			if(response.payWithVivoTerminalCloseTender.alertLevel.length === 0){
				response.nextModal = _nextModal("process");
			}
			if(callback) (callback)(response);
		}, null, null, 0);
	};

	var processOrder = function(callback){
		globalFunctions.v65http("/store/index.cfm?method=checkout.processOrder",{posProfile:session.posProfile,orderID:globalFunctions.retrieveOrderIDFromSession()}, function(response){
			if(globalData.posInfo.simpleWorkflow){
				response.nextModal = _nextModal("additionalOrderData");
			} else {
				response.nextModal = _nextModal("receipt");
			}
			(callback)(response);
		}, null, null, 3, 'retry');
	}

	var captureTipAndSignature = function(tip, orderTenderID, orderID, signature, productTransactionID, callback){
		signature = signature.replace("data:image/png;base64,", "");
		globalFunctions.v65http("/store/index.cfm?method=checkout.captureTipAndSignature",{
			tip:tip,
			orderTenderID:orderTenderID,
			posProfile:JSON.stringify(session.posProfile),
			orderID:globalFunctions.retrieveOrderIDFromSession(),
			productTransactionID: productTransactionID,
			signature:signature
		}, function(response){
			if(globalFunctions.isRefund()){
				response.refund.refundHeader.paymentType = response.refund.refundHeader.paymentType || session.refund.refundHeader.paymentType;
				angular.copy(response.refund, session.refund);
			}else{
				response.order.orderHeader.paymentType = response.order.orderHeader.paymentType || session.order.orderHeader.paymentType;
				angular.copy(response.order, session.order);
			}
			response.nextModal = _nextModal("process");
			(callback)(response);
		},"POST", null, 0);
	}

	var tip = function(tip,orderTenderID, callback){
		globalFunctions.v65http("/store/index.cfm?method=checkout.tip",{tip:tip,orderTenderID:orderTenderID,posProfile:session.posProfile,orderID:globalFunctions.retrieveOrderIDFromSession()}, function(response){
			if(globalFunctions.isRefund()){
				response.refund.refundHeader.paymentType = response.refund.refundHeader.paymentType || session.refund.refundHeader.paymentType;
				angular.copy(response.refund, session.refund);
			}else{
				response.order.orderHeader.paymentType = response.order.orderHeader.paymentType || session.order.orderHeader.paymentType;
				angular.copy(response.order, session.order);
			}
			response.nextModal = _nextModal("insertCard");
			(callback)(response);
		}, null, null, 0);
	}

	var removeTipFromOrder = function(orderTenderID, orderID, callback){
		globalFunctions.v65http("/store/index.cfm?method=checkout.removeTip",{orderTenderID:orderTenderID,posProfile:session.posProfile,orderID:orderID}, function(response){
			_updateSession(response);
			response.nextModal = _nextModal("tip");
			(callback)(response);
		}, null, null, 0);
	}
	
	var removeTipFromOrderForVivoPay = function(tip, orderTenderID, orderID, callback){
		globalFunctions.v65http("/store/index.cfm?method=checkout.removeTip",{tip: tip, orderTenderID:orderTenderID,posProfile:session.posProfile,orderID:orderID}, function(response){
			_updateSession(response);
			response.nextModal = _nextModal("tip");
			(callback)(response);
		}, null, null, 0);
	}

	var useCardOnFile = function(amountTendered, formData, productTransactionID, callback){
		globalFunctions.v65http("/store/index.cfm?method=checkout.useCardOnFile",{
			amountTendered:amountTendered,
			customerCreditCardID:formData.customerCreditCardID,
			posProfile:session.posProfile,orderID:globalFunctions.retrieveOrderIDFromSession(),
			productTransactionID: productTransactionID
		}, function(response){
			_updateSession(response);
			_setPaymentType("CreditCard");
			response.nextModal = _nextModal("tip");
			if(response.hasAdditionalOrderData){
				angular.copy(response.additionalOrderData, globalData.additionalOrderData);
			}
			(callback)(response);
		}, null, null, 0);
	}

	var useGiftCard = function(amountTendered, giftCode, callback){
		globalFunctions.v65http("/store/index.cfm?method=checkout.useGiftCard",{giftCardAmountTendered:amountTendered,giftCode:giftCode,posProfile:session.posProfile,orderID:globalFunctions.retrieveOrderIDFromSession()},function(response){
			_updateSession(response);
			response.nextModal = _nextModal("process");
			if(response.hasAdditionalOrderData){
				angular.copy(response.additionalOrderData, globalData.additionalOrderData);
			}
			(callback)(response);
		}, null, null, 0);
	}
	
	var precheckCompliance = function(callback){
		globalFunctions.v65http("/store/index.cfm?method=checkout.precheckCompliance",{posProfile:session.posProfile,orderID:globalFunctions.retrieveOrderIDFromSession()},function(response){
			if(callback) (callback)(response);
		}, null, null, 0);
	}


	//Private
	var _setPaymentType = function(paymentType){
		if(globalFunctions.isRefund()){
			session.refund.refundHeader.paymentType = paymentType;
		} else{
			session.order.orderHeader.paymentType = paymentType;
		}
	}
	
	var _updateSession = function(response){
		if(globalFunctions.isRefund()){
			angular.copy(response.refund, session.refund); 
		} else{
			angular.copy(response.order, session.order);
		}
	}
	
	var _nextModal = function(nextStep){
		var isRefund = globalFunctions.isRefund();
		var currentOrder = isRefund ? session.refund.refundHeader : session.order.orderHeader;
		
		switch(nextStep){
			// EMV Terminal
			case "terminal":
				if(currentOrder.paymentType == "Terminal"){
					nextModal = "v2/views/partials/modals/payWithEMV";
				}else{
					_nextModal("process");
				}
			break;

			// Fullsteam EMV
			case "fullsteamEMV":
				if(currentOrder.paymentType == "Terminal"){
					nextModal = "v2/views/partials/modals/payWithFullsteamEMV";
				}else{
					_nextModal("process");
				}
			break;

			// Vivo Terminal
			case "terminal-ble":
				if(currentOrder.paymentType == "Terminal" && session.posProfile.hasTips){
					nextModal = "v2/views/partials/modals/tip";
				}else{
					nextModal = 'v2/views/partials/modals/payWithVivo';
				}
			break;

			//Tip Screen
			case "tip":
				if(globalData.posInfo.combinedTip && session.posProfile.hasCombinedTipAndSignature && currentOrder.paymentType != "Terminal") {
					nextModal = "v2/views/partials/modals/tipAndSignature"
				} else if (session.posProfile.hasTips) {
					nextModal = "v2/views/partials/modals/tip";
				} else {
					_nextModal("insertCard");
				}
			break;

			//Insert card (EMV)
			case "insertCard":
				console.log("insert Card");
				if(currentOrder.paymentType == "Terminal"){
					nextModal = "v2/views/partials/modals/insertCard";
				}else{
					_nextModal("signature");
				}
			break;

			//Signature Screen
			case "signature":
				console.log("Signature");
				if(session.posProfile.hasCreditCardSignature){
					nextModal = "v2/views/partials/modals/signature";
				}else{
					_nextModal("process");
				}
			break;
			
			//Process Screen
			case "process":
				console.log("Process");
				var amountDueCents = Number(currentOrder.amountDueCents);
				if(amountDueCents <= 0){
					nextModal = "v2/views/partials/modals/process";
				}else{
					$rootScope.$emit('clearAmountTendered', false);
					nextModal = "";
				}
			break;

			//Receipt Screen
			case "receipt":
				console.log("Receipt");
				nextModal = "v2/views/partials/modals/receipt";
			break;

			//Additinal Order Data Screen
			case "additionalOrderData":
				console.log("Additional");
				if(session.posProfile.hasAdditionalOrderInfo == 1){
					nextModal="v2/views/partials/modals/additionalOrderData";
				}else if(globalData.posInfo.simpleWorkflow){
					_nextModal("simpleSuccess");
				}else{
					_nextModal("success");
				}
			break;

			//Success Screen
			case "success":
				console.log("Success");
				nextModal = isRefund ? "v2/views/partials/modals/refundSuccess" : "v2/views/partials/modals/success";
			break;
			
			// Come back to this when handling refunds
			case "simpleSuccess":
				console.log("Simple Success");
				nextModal = isRefund ? "v2/views/partials/modals/refundSuccess" : "v2/views/partials/modals/simpleSuccess";
			break;
			
			case "emailReceipt":
				console.log("Email Receipt");
				nextModal = "v2/views/partials/modals/emailReceipt";
			break;
			
		}

		return nextModal;
	}

	return{
		additionalOrderData:additionalOrderData,
		alternatePaymentMethod:alternatePaymentMethod,
		cameraCaptureCard:cameraCaptureCard,
		captureSignature:captureSignature,
		capturePayment:capturePayment,
		captureTipAndSignature:captureTipAndSignature,
		deleteCartObject:deleteCartObject,
		enterCardManually:enterCardManually,
		getAdditionalEmailsForContact:getAdditionalEmailsForContact,
		emailAddress:emailAddress,
		emailReceiptForm:emailReceiptForm,
		cancelEmailReceipt:cancelEmailReceipt,
		noReceipt:noReceipt,
		listAdditionalOrderData:listAdditionalOrderData,
		listPaymentTypes:listPaymentTypes,
		payWithCash: payWithCash,
		payWithLoyaltyPoints: payWithLoyaltyPoints,
		payWithTerminal:payWithTerminal,
		payWithTerminalStart:payWithTerminalStart,
		payWithEMVTerminal:payWithEMVTerminal,
		payWithEMVTerminalStart:payWithEMVTerminalStart,
		payWithFullsteamEMV:payWithFullsteamEMV,
		payWithFullsteamEMVStart:payWithFullsteamEMVStart,
		processOrder:processOrder,
		removeOrderTender:removeOrderTender,
		swipeCreditCard:swipeCreditCard,
		swipeGiftCard:swipeGiftCard,
		tip:tip,
		removeTipFromOrder:removeTipFromOrder,
		removeTipFromOrderForVivoPay: removeTipFromOrderForVivoPay,
		useCardOnFile:useCardOnFile,
		useGiftCard:useGiftCard,
		voidAndReAuthPayment:voidAndReAuthPayment,
		precheckCompliance:precheckCompliance,
		payWithVivoTerminalStart: payWithVivoTerminalStart,
		payWithVivoTerminal: payWithVivoTerminal,
		payWithVivoTerminalBeginChipEmulate: payWithVivoTerminalBeginChipEmulate,
		payWithVivoTerminalInit: payWithVivoTerminalInit,
		payWithVivoTerminalComplete: payWithVivoTerminalComplete,
		payWithVivoTerminalAlreadyProcessed: payWithVivoTerminalAlreadyProcessed
	};
});
