pos.controller("initiateFullsteamEMVSetup", function($scope, $localStorage, globalData, globalFunctions, fullsteamEMVService){
	//Variables
	$scope.modalActionDefaults();
	$scope.modalButtonLoading = false;
	
	$scope.$emit('modalLoading', false);
	$scope.modalTitle = "Setup Terminal";
	$scope.modalSubmitTitle = "Create";
	$scope.modalCancelTitle = "Back";
	$scope.posInfo = globalData.posInfo;
	$scope.storage = $localStorage;
	
	$scope.addNewEMVTerminal = function(){
		$scope.modalActionDefaults($scope.modalData);
		fullsteamEMVService.addEMVTerminal(
			$scope.formData.nickName,
			$scope.formData.serialNumber,
			function(response){
				// Auto-save payment terminal
				$scope.storage.paymentTerminal=response;
				$scope.openModal("v2/views/partials/modals/deviceSettings",{terminalID:response.paymentTerminalID});
				globalFunctions.alertMessage("modal","success","Terminal created!");
			}
		);
	}
	
	$scope.closeModal = function(){
		$scope.modalActionDefaults($scope.formData);
		$scope.openModal("v2/views/partials/modals/deviceSettings");
	};

	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		$scope.$emit('modalLoading', true);
		$scope.addNewEMVTerminal();
	};
});

pos.controller("editEMVTerminal", function($scope, $localStorage, globalFunctions, fullsteamEMVService){
	//Variables
	$scope.modalActionDefaults();
	$scope.modalButtonLoading = false;
	
	$scope.$emit('modalLoading', false);
	$scope.modalData;
	$scope.modalTitle = "Edit EMV Terminal";
	$scope.modalSubmitTitle = "Save";
	$scope.modalCancelTitle = "Back";
	$scope.storage = $localStorage;
	$scope.tipsEnabledPreviously = $scope.modalData.enableTips;
	$scope.receiptsEnabledPreviously = $scope.modalData.enableReceipts;
	$scope.customTipsEnabledPreviously = $scope.modalData.enableCustomTips;
	$scope.customTip1SetPreviously = $scope.modalData.customTip1;
	$scope.customTip2SetPreviously = $scope.modalData.customTip2;
	$scope.customTip3SetPreviously = $scope.modalData.customTip3;
	$scope.isBleTerminalPreviously = $scope.modalData.isBleTerminal;

	//Logic

	//Functions
	$scope.closeModal = function(){
		$scope.modalActionDefaults($scope.formData);
		$scope.openModal("v2/views/partials/modals/deviceSettings");
	};

	$scope.deleteEMVTerminal = function(){
		$scope.modalActionDefaults($scope.formData);
		fullsteamEMVService.deleteEMVTerminal($scope.formData.terminalID, function(){
			delete $scope.$storage.emvTerminal;
			$scope.storage.emvTerminal = {};
			$scope.openModal("v2/views/partials/modals/deviceSettings");
			globalFunctions.alertMessage("modal","success","Terminal has been deleted.");
		});
	};

	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);

		if ($scope.formData.terminal_manufacturer_id === 4) {
			$scope.formData.isBleTerminal = 1;
		} else {
			$scope.formData.isBleTerminal = 0;
		}

		fullsteamEMVService.editEMVTerminal(
			$scope.formData.terminalID,
			$scope.formData.nickName,
			$scope.formData.isBleTerminal,
			$scope.formData.enableTips,
			$scope.formData.enableReceipts,
			$scope.formData.enableCustomTips,
			$scope.formData.customTip1,
			$scope.formData.customTip2,
			$scope.formData.customTip3,
			function(response){
				var successMessage = "Terminal has been updated.";
				// If tips/receipts were toggled, terminal needs to be restarted to take effect
				if ($scope.tipsEnabledPreviously != $scope.formData.enableTips || 
					$scope.receiptsEnabledPreviously != $scope.formData.enableReceipts ||
					$scope.customTipsEnabledPreviously != $scope.formData.enableCustomTips || 
					$scope.customTip1SetPreviously != $scope.formData.customTip1 ||
					$scope.customTip2SetPreviously != $scope.formData.customTip2 || 
					$scope.customTip3SetPreviously != $scope.formData.customTip3) {
					successMessage = "Settings saved! Restart the terminal to complete update.";
				}
				$scope.storage.emvTerminal=response.terminal;
				$scope.openModal("v2/views/partials/modals/deviceSettings");
				globalFunctions.alertMessage("modal","success",successMessage);
			}
		);
	};
	
	$scope.clearPercentages = function(){
		if($scope.formData.enableCustomTips != 1) {
			$scope.formData.customTip1 = '';
			$scope.formData.customTip2 = '';
			$scope.formData.customTip3 = '';
		}
	};

});